import React, { Fragment } from 'react'
import { useTranslate } from 'react-polyglot'
import { useLocation, useParams } from 'react-router-dom'

import useFetchDataById from 'hooks/useFetchDataById'

import { BASE_FILE_URL } from 'utils/axiosClient'

import ENTITY from 'constants/entities'
import ROUTE from 'constants/routes'

import footerLogo from 'assets/images/ewasoft-logo.png'

const Footer = ({ company }) => {
    const t = useTranslate()
    const { projectId } = useParams()
    const { pathname } = useLocation()

    const nonCampaignPage = !pathname.includes(`${ROUTE.CAMPAIGNS}/`)

    const {
        name,
        footerImagePath,
        imagePath,
        copyrightText,
        termsAndConditionsPath,
        privacyPolicyPath,
        street,
        zip,
        city,
        phone,
        email,
    } = company || {}

    const { data } = useFetchDataById(ENTITY.PROJECT, projectId)
    const { _links } = data || []

    const copyrightSign = String.fromCodePoint(0x00a9)
    const copyright = `${copyrightSign} ${t(
        'general.copyright'
    )} ${new Date().getFullYear()} | ${name ? name + ' | ' : ''}`

    const showCompanyInfo = !!(termsAndConditionsPath || privacyPolicyPath)
    const showGetInTouchInfo = !!(street || zip || city || phone || email)

    const getLink = (item) => {
        if (item?.linkName && item?.linkURL) {
            return (
                <a href={item.linkURL} target="_blank">
                    {item.linkName}
                </a>
            )
        } else {
            if (item?.linkName) {
                return <p>{item.linkName}</p>
            }
            return (
                <a href={item.linkURL} target="_blank">
                    {item.linkURL}
                </a>
            )
        }
    }

    return (
        <footer
            id="footer-main"
            className="m-footer -sixteen"
            style={
                footerImagePath && {
                    backgroundImage: `url(${BASE_FILE_URL}/${encodeURI(
                        footerImagePath
                    )}`,
                }
            }
        >
            <div className="_wr">
                <div className="_w">
                    <div className="_l3 m-footer__column -main">
                        <div className="m-footer__column--logo">
                            <img
                                src={
                                    imagePath
                                        ? `${BASE_FILE_URL}/${imagePath}`
                                        : footerLogo
                                }
                            ></img>
                        </div>
                    </div>

                    {_links?.length > 0 && !nonCampaignPage && (
                        <div className="_s4 _l3 m-footer__column">
                            <span className="-mainLink">
                                {t('general.linkMain')}
                            </span>
                            <div className="m-footer__column--links">
                                {_links.map((item, index) => (
                                    <Fragment key={index}>
                                        {getLink(item)}
                                    </Fragment>
                                ))}
                            </div>
                        </div>
                    )}

                    {showCompanyInfo && (
                        <div className="_s4 _l2 m-footer__column -marTop">
                            <h6 className="-mainLink">{t('footer.company')}</h6>
                            <div className="m-footer__column--links">
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    href={
                                        termsAndConditionsPath &&
                                        `${BASE_FILE_URL}/${termsAndConditionsPath}`
                                    }
                                >
                                    {t('footer.terms')}
                                </a>

                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    href={
                                        privacyPolicyPath &&
                                        `${BASE_FILE_URL}/${privacyPolicyPath}`
                                    }
                                >
                                    {t('footer.privacy')}
                                </a>
                            </div>
                        </div>
                    )}

                    {showGetInTouchInfo && (
                        <div className="_s4 _l3 m-footer__column -marTop">
                            <span className="-mainLink">
                                {t('footer.getInTouch')}
                            </span>
                            <div className="m-footer__column--links">
                                {street && <span>{street}</span>}
                                {zip && city && (
                                    <span>
                                        {zip} {city}
                                    </span>
                                )}
                                {phone && <a href={`tel:${phone}`}>{phone}</a>}
                                {email && (
                                    <a href={`mailto:${email}`}>{email}</a>
                                )}
                            </div>
                        </div>
                    )}
                </div>

                <div className="m-footer__column--links -copyright">
                    <span className="-copy">
                        {`${copyright} ${t('general.allRightReserved')} ${
                            copyrightText ? ' | ' + copyrightText : ''
                        }`}
                    </span>
                </div>
            </div>
        </footer>
    )
}

export default Footer
