export const DEFAULT_LANGUAGE = 'en'

export const LARGE_SCREEN_WIDTH = 991
export const MEDIUM_SCREEN_WIDTH = 768

// TODO:: Sync this with backend
export const ROLES = {
    ROLE_VOTER: {
        id: 2,
        name: 'ROLE_VOTER',
        label: 'role.voter',
    },
    ROLE_APPLICANT: {
        id: 3,
        name: 'ROLE_APPLICANT',
        label: 'role.applicant',
    },
    ROLE_COMPANY_ADMIN: {
        id: 4,
        name: 'ROLE_COMPANY_ADMIN',
        label: 'role.companyAdmin',
    },
    ROLE_SUPER_ADMIN: {
        id: 5,
        name: 'ROLE_SUPER_ADMIN',
        label: 'role.superAdmin',
    },
}

export const ALL_ROLES = [
    ROLES.ROLE_SUPER_ADMIN,
    ROLES.ROLE_COMPANY_ADMIN,
    ROLES.ROLE_VOTER,
    ROLES.ROLE_APPLICANT,
]

export const ALERT_TYPES = {
    SUCCESS: 'success',
    ERROR: 'error',
    DEFAULT: 'default',
}

export const ALERT_POSITIONS = {
    CENTER: 'center',
    RIGHT: 'right',
}

export const BUTTON_TYPE = {
    BUTTON: 'button',
    SUBMIT: 'submit',
    RESET: 'reset',
}

export const BUTTON_STATUS = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    BASIC: 'basic',
    TERTIARY: 'tertiary',
    VOTE: 'primary -voteButton',
    BUTTON_WITH_ICON_PRIMARY: 'primary -buttonWithIcon',
    BUTTON_WITH_ICON_BASIC: 'basic -buttonWithIcon',
    EXPORT: 'basic -exportButton',
}

export const ICON_SIZE = {
    TINY: 16,
    SMALL: 20,
    MEDIUM: 32,
    LARGE: 48,
}

export const INPUT_FILED_TYPE = {
    TEXT: 'text',
    PASSWORD: 'password',
    EMAIL: 'email',
    FILE: 'file',
    CHECKBOX: 'checkbox',
    NUMBER: 'number',
}

export const FILE_UPLOAD_TYPE = {
    IMAGE: 'image',
    FILE: 'file',
    VIDEO: 'video',
}

export const MODAL_TYPES = {
    SMALL: '-small',
}

export const SORT_TYPES = {
    ASC: 1,
    DESC: -1,
}

export const TABLE_FILTER_TYPE = {
    TEXT: 'text',
    DATE_TIME: 'date-time',
    SELECT: 'select',
    CHECKBOX: 'checkbox',
    REMOVE: 'remove',
}

export const SELECT_VALUE_TYPE = {
    STRING: 'string',
    OBJECT: 'object',
}

export const YES_NO_OPTIONS = (t) => [
    { id: 1, name: t('general.yes') },
    { id: 0, name: t('general.no') },
]

export const APPLICATION_STATUS = {
    DRAFT: { id: 1, name: 'Draft', icon: 'draft' },
    WAITING_FOR_APPROVAL: {
        id: 2,
        name: 'Waiting for approval',
        icon: 'waiting',
    },
    APPROVED: { id: 3, name: 'Approved', icon: 'checkRounded' },
    REJECTED: { id: 4, name: 'Rejected', icon: 'cancel' },
}

export const LAYOUTS = {
    ADMIN: 'adminLayout',
    AUTH: 'authLayout',
}
