import React from 'react'
import { PropTypes } from 'prop-types'
import { primaryColor, secondaryColor } from 'constants/colors'

export const ICON_SIZE = {
    LARGE: 24,
    SMALL: 18,
}

const getViewBoxSize = (size) => {
    switch (size) {
        case ICON_SIZE.LARGE:
        case ICON_SIZE.SMALL:
        default:
            return 24
    }
}

const renderIcon = (name, color, size, fill, title) => {
    const boxSize = getViewBoxSize(size)
    switch (name) {
        case 'home':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-gauge"
                    width={size}
                    height={size}
                    viewBox={`0 0 ${boxSize} ${boxSize}`}
                    strokeWidth="1"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="12" cy="12" r="9" />
                    <circle cx="12" cy="12" r="1" />
                    <line x1="13.41" y1="10.59" x2="16" y2="8" />
                    <path d="M7 12a5 5 0 0 1 5 -5" />
                </svg>
            )
        case 'chevron-left':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-chevron-left"
                    width={size}
                    height={size}
                    viewBox={`0 0 ${boxSize} ${boxSize}`}
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M15 6L9 12L15 18" stroke="currentColor" />
                </svg>
            )
        case 'chevron-right':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-chevron-right"
                    width={size}
                    height={size}
                    viewBox={`0 0 ${boxSize} ${boxSize}`}
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M9 6L15 12L9 18" stroke="currentColor" />
                </svg>
            )
        case 'sort':
            return (
                <svg
                    width={size}
                    height={size}
                    stroke={color}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g fill="none" fillRule="evenodd">
                        <path fill="none" d="M-130-99h1440v700H-130z" />
                        <g strokeLinecap="round" strokeLinejoin="round">
                            <path d="M1 .857h6M1 6h6M1 11.143h7.714M10.429 3.429L13" />
                        </g>
                    </g>
                </svg>
            )
        case 'sort-asc':
            return (
                <svg
                    width={size}
                    height={size}
                    stroke={color}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g fill="none" fillRule="evenodd">
                        <path fill="none" d="M-130-99h1440v700H-130z" />
                        <g strokeLinecap="round" strokeLinejoin="round">
                            <path d="M1 .857h6M1 6h6M1 11.143h7.714M10.429 3.429L13 .857l2.571 2.572M13 .857v10.286" />
                        </g>
                    </g>
                </svg>
            )
        case 'sort-desc':
            return (
                <svg
                    width={size}
                    height={size}
                    stroke={color}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g fill="none" fillRule="evenodd">
                        <path fill="none" d="M-214-99h1440v700H-214z" />
                        <g strokeLinecap="round" strokeLinejoin="round">
                            <path d="M1 .857h7.714M1 6h6M1 11.143h6M10.429 8.571L13 11.143l2.571-2.572M13 .857v10.286" />
                            <g>
                                <path d="M1 .857h7.714M1 6h6M1 11.143h6M10.429 8.571L13 11.143l2.571-2.572M13 .857v10.286" />
                            </g>
                        </g>
                    </g>
                </svg>
            )
        case 'pencil':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-pencil"
                    width={size}
                    height={size}
                    viewBox={`0 -2 19 19`}
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path
                        d="M10.241 1.991 12 3.75m-1 4.583V11.5A1.5 1.5 0 0 1 9.5 13h-7A1.5 1.5 0 0 1 1 11.5v-7A1.5 1.5 0 0 1 2.5 3h3.167m4.574-1.009L11.366.866a1.25 1.25 0 0 1 1.768 1.768l-7.08 7.08a3 3 0 0 1-1.264.753L3 11l.533-1.79a3 3 0 0 1 .754-1.265l5.954-5.954v0Z"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )
        case 'trash':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-trash"
                    width={size}
                    height={size}
                    viewBox={`0 -2 19 19`}
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path
                        d="m7.827 5-.231 6m-3.192 0-.23-6m6.645-2.14c.228.035.454.071.681.11m-.681-.11-.712 9.255A1.5 1.5 0 0 1 8.61 13.5H3.389a1.5 1.5 0 0 1-1.496-1.385L1.181 2.86m9.638 0A32.077 32.077 0 0 0 8.5 2.595m-8 .375c.227-.04.453-.076.681-.11m0 0c.77-.116 1.543-.204 2.319-.265m5 0v-.61c0-.787-.607-1.443-1.393-1.468a34.642 34.642 0 0 0-2.214 0C4.107.542 3.5 1.2 3.5 1.985v.61m5 0a32.443 32.443 0 0 0-5 0"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )
        case 'user':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-user"
                    width={size}
                    height={size}
                    viewBox={`0 0 ${boxSize} ${boxSize}`}
                    strokeWidth="1"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="12" cy="7" r="4" />
                    <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                </svg>
            )
        case 'lock':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-lock"
                    width={size}
                    height={size}
                    viewBox={`0 0 ${boxSize} ${boxSize}`}
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <rect x="5" y="11" width="14" height="10" rx="2" />
                    <circle cx="12" cy="16" r="1" />
                    <path d="M8 11v-4a4 4 0 0 1 8 0v4" />
                </svg>
            )
        case 'email':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-at"
                    width={size}
                    height={size}
                    viewBox={`0 0 ${boxSize} ${boxSize}`}
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="12" cy="12" r="4" />
                    <path d="M16 12v1.5a2.5 2.5 0 0 0 5 0v-1.5a9 9 0 1 0 -5.5 8.28" />
                </svg>
            )
        case 'checkRound':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-lock"
                    width={size}
                    height={size}
                    viewBox={`0 0 ${boxSize} ${boxSize}`}
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M17 8.269v.736a8 8 0 1 1-4.744-7.312M17 2.6l-8 8.008-2.4-2.4" />
                </svg>
            )
        case 'checkShield':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-lock"
                    width={size}
                    height={size}
                    viewBox={`0 0 ${boxSize} ${boxSize}`}
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="m5.375 9.577 1.75 1.82 3.938-4.093M15 10.032c0 4.464-4.685 7.71-6.39 8.744-.193.117-.29.176-.427.206a.96.96 0 0 1-.366 0c-.137-.03-.234-.089-.428-.206C5.685 17.742 1 14.496 1 10.032V5.683c0-.727 0-1.09.114-1.403.101-.276.266-.523.479-.718.241-.22.569-.348 1.224-.604L7.508 1.13c.182-.071.273-.107.367-.12a.842.842 0 0 1 .25 0c.094.013.185.049.367.12l4.691 1.828c.655.256.983.383 1.224.604.213.196.377.442.479.718.114.312.114.676.114 1.403v4.349Z" />
                </svg>
            )
        case 'upload':
            return (
                <svg
                    width="80"
                    height="80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="80" height="80" rx="4" fill="#F7F7F9" />
                    <rect
                        x=".5"
                        y=".5"
                        width="79"
                        height="79"
                        rx="3.5"
                        stroke="#192132"
                        strokeOpacity=".15"
                        strokeDasharray="3 3"
                    />
                    <path
                        opacity=".5"
                        stroke="#192132"
                        d="M40.413 29v22M51 39.654H29"
                    />
                </svg>
            )
        case 'uploadimage':
            return (
                <svg
                    width="80"
                    height="80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="80" height="80" rx="4" fill="#F7F7F9" />
                    <rect
                        x=".5"
                        y=".5"
                        width="79"
                        height="79"
                        rx="3.5"
                        stroke="#192132"
                        strokeOpacity=".15"
                        strokeDasharray="3 3"
                    />
                    <path
                        opacity=".5"
                        d="m20 48.063 10.847-10.577a4.74 4.74 0 0 1 1.535-1 4.836 4.836 0 0 1 3.62 0 4.74 4.74 0 0 1 1.535 1l10.848 10.577m-3.154-3.076 2.962-2.888a4.74 4.74 0 0 1 1.535-1 4.836 4.836 0 0 1 3.62 0 4.741 4.741 0 0 1 1.536 1L61 48.063M23.154 55.75h34.692c.837 0 1.639-.324 2.23-.9A3.037 3.037 0 0 0 61 52.674v-24.6c0-.816-.332-1.598-.924-2.174a3.196 3.196 0 0 0-2.23-.901H23.154c-.837 0-1.639.324-2.23.9A3.037 3.037 0 0 0 20 28.076v24.6c0 .816.332 1.598.924 2.174.591.577 1.393.901 2.23.901ZM45.23 32.687h.017v.017h-.017v-.017Zm.788 0c0 .204-.083.4-.23.544a.799.799 0 0 1-1.116 0 .76.76 0 0 1 0-1.087.799.799 0 0 1 1.115 0c.148.144.231.34.231.544v0Z"
                        stroke="#192132"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )

        case 'uploadvideo':
            return (
                <svg
                    width="80"
                    height="80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="80" height="80" rx="4" fill="#F7F7F9" />
                    <rect
                        x=".5"
                        y=".5"
                        width="79"
                        height="79"
                        rx="3.5"
                        stroke="#192132"
                        strokeOpacity=".15"
                        strokeDasharray="3 3"
                    />
                    <path
                        opacity=".5"
                        d="m49.333 37 11.13-5.69a2.399 2.399 0 0 1 2.377.11c.355.223.647.536.85.908.203.372.31.791.31 1.217v16.91c0 .426-.107.845-.31 1.217a2.477 2.477 0 0 1-.85.909 2.406 2.406 0 0 1-2.377.109L49.333 47V37ZM24.89 57h19.555c1.297 0 2.54-.527 3.457-1.465A5.058 5.058 0 0 0 49.333 52V32a5.058 5.058 0 0 0-1.432-3.535A4.834 4.834 0 0 0 44.444 27H24.89c-1.297 0-2.54.527-3.457 1.465A5.058 5.058 0 0 0 20 32v20c0 1.326.515 2.598 1.432 3.535A4.835 4.835 0 0 0 24.889 57Z"
                        stroke="#192132"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        opacity=".5"
                        stroke="#192132"
                        d="M34.5 35v13M41 41.5H28"
                    />
                </svg>
            )

        case 'filter':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="14"
                    fill="none"
                >
                    <path
                        d="M3 1a1 1 0 1 0-2 0v7.268a2 2 0 0 0 0 3.464V13a1 1 0 1 0 2 0v-1.268a2 2 0 0 0 0-3.464V1Zm6 0a1 1 0 0 0-2 0v1.268a2 2 0 0 0 0 3.464V13a1 1 0 1 0 2 0V5.732a2 2 0 0 0 0-3.464V1Zm5-1a1 1 0 0 1 1 1v7.268a2 2 0 0 1 0 3.464V13a1 1 0 0 1-2 0v-1.268a1.999 1.999 0 0 1 0-3.464V1a1 1 0 0 1 1-1Z"
                        fill="#E5361C"
                    />
                </svg>
            )
        case 'search':
            return (
                <svg
                    width="16"
                    height="16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        opacity=".5"
                        d="m15 15-4.667-4.667m1.556-3.889a5.445 5.445 0 1 1-10.89 0 5.445 5.445 0 0 1 10.89 0Z"
                        stroke="#192132"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )
        case 'plus':
            return (
                <svg
                    width="14"
                    height="14"
                    stroke={color}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M7 1v6m0 0v6m0-6h6M7 7H1"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )
        case 'send':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={size}
                    height={size}
                    fill="none"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    className="icon icon-tabler icon-tabler-send"
                    viewBox="0 0 24 24"
                >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M10 14 21 3M21 3l-6.5 18a.55.55 0 0 1-1 0L10 14l-7-3.5a.55.55 0 0 1 0-1L21 3" />
                </svg>
            )
        case 'import':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="none"
                >
                    <path
                        stroke={color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M1 10v1.5A1.5 1.5 0 0 0 2.5 13h9a1.5 1.5 0 0 0 1.5-1.5V10M4 4l3-3m0 0 3 3M7 1v9"
                    />
                </svg>
            )
        case 'rounded-plus':
            return (
                <svg
                    width={size}
                    height={size}
                    stroke={color}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M9 6.333v5.334M11.667 9H6.333M17 9A7.999 7.999 0 1 1 1.002 9 7.999 7.999 0 0 1 17 9Z"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )
        case 'inactive':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-power"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke={secondaryColor}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M7 6a7.75 7.75 0 1 0 10 0" />
                    <line x1="12" y1="4" x2="12" y2="12" />
                </svg>
            )
        case 'active':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-power"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M7 6a7.75 7.75 0 1 0 10 0" />
                    <line x1="12" y1="4" x2="12" y2="12" />
                </svg>
            )
        case 'key':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-lock"
                    width={size}
                    height={size}
                    viewBox={`0 -2 19 19`}
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path
                        d="M9.30769 2.84346C9.79732 2.84346 10.2669 3.03802 10.6131 3.38433C10.9593 3.73064 11.1538 4.20034 11.1538 4.69011M13 4.69011C13.0001 5.22878 12.8824 5.76095 12.6551 6.24931C12.4278 6.73766 12.0965 7.17038 11.6844 7.51711C11.2722 7.86384 10.7892 8.1162 10.2693 8.25649C9.74935 8.39678 9.20501 8.42161 8.67446 8.32922C8.328 8.26952 7.96123 8.34523 7.71262 8.59391L6.07692 10.23H4.69231V11.615H3.30769V13H1V11.2654C1 10.8979 1.14585 10.5452 1.40554 10.286L5.40492 6.2856C5.65354 6.03692 5.72923 5.67006 5.66954 5.3235C5.58227 4.81947 5.60064 4.3028 5.72346 3.80624C5.84629 3.30968 6.0709 2.84406 6.38306 2.43888C6.69522 2.0337 7.08813 1.6978 7.53687 1.45247C7.98562 1.20714 8.48042 1.05773 8.98992 1.0137C9.49942 0.969679 10.0125 1.032 10.4967 1.19672C10.9808 1.36145 11.4255 1.62497 11.8025 1.9706C12.1795 2.31624 12.4806 2.73643 12.6868 3.20457C12.8929 3.67271 12.9996 4.17857 13 4.69011V4.69011Z"
                        stroke="#192132"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )
        case 'checkRounded':
            return (
                <svg
                    width="20"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle cx="10" cy="10" r="10" fill="#E6FDEA" />
                    <path
                        d="M7 10.4 8.714 12 13 8"
                        stroke="#70D486"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )
        case 'export':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={size}
                    height={size}
                    fill="none"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="icon icon-tabler icon-tabler-download"
                    viewBox="0 0 24 24"
                >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-2M7 11l5 5 5-5M12 4v12" />
                </svg>
            )

        case 'cancel':
            return (
                <svg
                    width="20"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle cx="10" cy="10" r="10" fill="#FDE6E6" />
                    <path
                        d="m8 12 4-4M8 8l4 4"
                        stroke="#E46060"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )
        case 'waiting':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 100 100"
                >
                    <path
                        fill="#f4d42a"
                        fillOpacity=".2"
                        vectorEffect="non-scaling-stroke"
                        transform="translate(.89 1)"
                        d="M49 0c27.048 0 49 21.952 49 49S76.048 98 49 98 0 76.048 0 49 21.952 0 49 0z"
                    />
                    <path
                        fill="#f4d42a"
                        vectorEffect="non-scaling-stroke"
                        transform="translate(24.89 16.68)"
                        d="M0 4.165A4.161 4.161 0 0 1 4.165 0h41.657a4.161 4.161 0 0 1 4.165 4.165 4.161 4.161 0 0 1-4.166 4.166v1.432c0 5.52-2.2 10.817-6.105 14.723l-8.826 8.839 8.84 8.839a20.832 20.832 0 0 1 6.104 14.722v1.432A4.161 4.161 0 0 1 50 62.484a4.161 4.161 0 0 1-4.166 4.165H4.165A4.161 4.161 0 0 1 0 62.484a4.161 4.161 0 0 1 4.165-4.166v-1.432c0-5.52 2.2-10.817 6.105-14.722l8.826-8.84-8.826-8.838A20.832 20.832 0 0 1 4.165 9.763V8.331A4.161 4.161 0 0 1 0 4.165zm12.496 4.166v1.432c0 3.32 1.315 6.495 3.658 8.839l8.84 8.826 8.838-8.84A12.493 12.493 0 0 0 37.49 9.75V8.33zm0 49.987H37.49v-1.432c0-3.32-1.315-6.495-3.658-8.839l-8.839-8.826-8.839 8.839a12.493 12.493 0 0 0-3.658 8.839v1.432z"
                    />
                </svg>
            )

        case 'draft':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 100 100"
                >
                    <path
                        fill="#688BB6"
                        fillOpacity=".2"
                        vectorEffect="non-scaling-stroke"
                        transform="translate(1.5 1)"
                        d="M49 0c27.048 0 49 21.952 49 49S76.048 98 49 98 0 76.048 0 49 21.952 0 49 0z"
                    />
                    <path
                        fill="#688BB6"
                        vectorEffect="non-scaling-stroke"
                        transform="translate(25.5 25)"
                        d="m40.12 22.586 1.104-1.104-3.314-3.314-6.073-6.07-3.314-3.315-1.105 1.105-2.21 2.21L5.73 31.57a8.666 8.666 0 0 0-2.171 3.656L.097 46.996c-.245.82-.02 1.71.596 2.317.616.606 1.497.83 2.317.596l11.764-3.46a8.67 8.67 0 0 0 3.657-2.17L37.91 24.804zM15.642 39.048l-.89 2.22c-.39.302-.83.527-1.3.674L5.806 44.19l2.25-7.635c.137-.479.371-.919.675-1.3l2.22-.89v3.129c0 .86.704 1.564 1.564 1.564h3.13zM35.465 1.833 34.057 3.25l-2.21 2.21-1.114 1.104 3.314 3.314 6.073 6.07 3.314 3.315 1.105-1.105 2.21-2.21 1.417-1.417a6.252 6.252 0 0 0 0-8.847l-3.843-3.851a6.258 6.258 0 0 0-8.85 0zM30.83 18.256 16.748 32.332a1.57 1.57 0 0 1-2.21 0 1.569 1.569 0 0 1 0-2.209L28.62 16.046a1.57 1.57 0 0 1 2.21 0 1.569 1.569 0 0 1 0 2.21z"
                    />
                </svg>
            )
        case 'back':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-arrow-narrow-left"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <line x1="5" y1="12" x2="19" y2="12" />
                    <line x1="5" y1="12" x2="9" y2="16" />
                    <line x1="5" y1="12" x2="9" y2="8" />
                </svg>
            )
        case 'link':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-link"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#2c3e50"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5" />
                    <path d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5" />
                </svg>
            )
        case 'camera':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={size}
                    height={size}
                    fill="none"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    className="icon icon-tabler icon-tabler-camera"
                    viewBox="0 0 24 24"
                >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path
                        fill={primaryColor}
                        stroke="#f7f7f9"
                        d="M5 7h1a2 2 0 0 0 2-2 1 1 0 0 1 1-1h6a1 1 0 0 1 1 1 2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2"
                    />
                    <circle
                        fill={primaryColor}
                        stroke="#f7f7f9"
                        cx="12"
                        cy="13"
                        r="3"
                    />
                </svg>
            )
        case 'redArrow':
            return (
                <svg
                    width={size}
                    height={size}
                    viewBox="0 0 21 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M20.3536 4.35355C20.5488 4.15829 20.5488 3.84171 20.3536 3.64645L17.1716 0.464466C16.9763 0.269204 16.6597 0.269204 16.4645 0.464466C16.2692 0.659728 16.2692 0.976311 16.4645 1.17157L19.2929 4L16.4645 6.82843C16.2692 7.02369 16.2692 7.34027 16.4645 7.53553C16.6597 7.7308 16.9763 7.7308 17.1716 7.53553L20.3536 4.35355ZM0 4.5H20V3.5H0V4.5Z"
                        fill={secondaryColor}
                    />
                </svg>
            )
        case 'arrow':
            return (
                <svg
                    width="7"
                    height="5"
                    viewBox="0 0 7 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        opacity="0.5"
                        d="M4.24329 4.17412C3.84605 4.6155 3.15395 4.6155 2.75671 4.17412L0.502069 1.66897C-0.077101 1.02544 0.379591 1.16055e-07 1.24536 1.88886e-07L5.75464 5.68218e-07C6.62041 6.41048e-07 7.0771 1.02544 6.49793 1.66897L4.24329 4.17412Z"
                        fill="#192132"
                    />
                </svg>
            )
        case 'date':
            return (
                <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M4.5 2V3.5M11.5 2V3.5M2 12.5V5C2 4.60218 2.15804 4.22064 2.43934 3.93934C2.72064 3.65804 3.10218 3.5 3.5 3.5H12.5C12.8978 3.5 13.2794 3.65804 13.5607 3.93934C13.842 4.22064 14 4.60218 14 5V12.5M2 12.5C2 12.8978 2.15804 13.2794 2.43934 13.5607C2.72064 13.842 3.10218 14 3.5 14H12.5C12.8978 14 13.2794 13.842 13.5607 13.5607C13.842 13.2794 14 12.8978 14 12.5M2 12.5V7.5C2 7.10218 2.15804 6.72064 2.43934 6.43934C2.72064 6.15804 3.10218 6 3.5 6H12.5C12.8978 6 13.2794 6.15804 13.5607 6.43934C13.842 6.72064 14 7.10218 14 7.5V12.5"
                        stroke="#484848"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )
        default:
            return null
    }
}

const Icon = ({ name, color, size, fill, title }) => {
    return renderIcon(name, color, size, fill, title)
}

Icon.propTypes = {
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    size: PropTypes.number,
    title: PropTypes.string,
}

Icon.defaultProps = {
    color: primaryColor,
    size: ICON_SIZE.LARGE,
    fill: 'none',
    title: '',
}

export default Icon
