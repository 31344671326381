import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import AvatarDropdown from './AvatarDropdown'

import { BASE_FILE_URL } from 'utils/axiosClient'
import ROUTE from 'constants/routes'

const Header = ({ openSidebar, setOpenSidebar, hamBtnRef }) => {
    const { currentUser } = useContext(CurrentUserContext)

    const companyImage = currentUser?.company?.imagePath

    return (
        <header className="m-adminHeader -topLevel">
            <Link
                to={ROUTE.CAMPAIGNS}
                className="m-nav__logo"
                style={
                    companyImage && {
                        backgroundImage: `url(${BASE_FILE_URL}/${encodeURI(
                            companyImage
                        )}`,
                    }
                }
            ></Link>
            <div
                className="m-toggler"
                ref={hamBtnRef}
                onClick={() => setOpenSidebar(!openSidebar)}
            >
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div className="m-adminHeader__main">
                <AvatarDropdown />
            </div>
        </header>
    )
}

Header.propTypes = {
    openSidebar: PropTypes.bool.isRequired,
    setOpenSidebar: PropTypes.func.isRequired,
}

export default Header
