import React from 'react'
import { Link } from 'react-router-dom'

import useWindowDimensions from 'hooks/useWindowDimension'

import { BASE_FILE_URL } from 'utils/axiosClient'

import ROUTE from 'constants/routes'
import { LARGE_SCREEN_WIDTH } from 'constants/enums'

import AvatarDropdown from 'components/layouts/AdminLayout/Header/AvatarDropdown'

const Header = ({ company, openSidebar, setOpenSidebar, hamBtnRef }) => {
    const { width } = useWindowDimensions()

    const isLargeScreen = width > LARGE_SCREEN_WIDTH

    return (
        <header>
            <nav>
                <div className={`${isLargeScreen ? '_wr' : ''}`}>
                    <div className="_w">
                        <div className="_12 m-nav -auth">
                            <div
                                className="m-toggler -auth"
                                ref={hamBtnRef}
                                onClick={() => setOpenSidebar(!openSidebar)}
                            >
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>

                            <Link
                                to={ROUTE.CAMPAIGNS}
                                className="m-nav__logo -auth"
                                style={
                                    company?.imagePath && {
                                        backgroundImage: `url(${BASE_FILE_URL}/${encodeURI(
                                            company?.imagePath
                                        )}`,
                                    }
                                }
                            />

                            {isLargeScreen && (
                                <ul className="m-adminHeader__main">
                                    <li className={`m-nav__items--avatar`}>
                                        <AvatarDropdown />
                                    </li>
                                </ul>
                            )}
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header
