import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import loadable from 'utils/loadable'

import ROUTE from 'constants/routes'

import NoAuthLayout from 'components/layouts/NoAuthLayout'

const Login = loadable(() => import('screens/noAuth/Login'))
const ForgotPassword = loadable(() => import('screens/noAuth/ForgotPassword'))
const ResetPassword = loadable(() => import('screens/noAuth/ResetPassword'))

const NoAuthRoutes = () => {
    return (
        <NoAuthLayout>
            <Routes>
                <Route path={ROUTE.LOGIN} element={<Login />} />
                <Route
                    path={`${ROUTE.FORGOT_PASSWORD}`}
                    element={<ForgotPassword />}
                />
                <Route
                    path={`${ROUTE.SET_PASSWORD}/:token`}
                    element={<ResetPassword buttonTitle="button.addPassword" />}
                />
                <Route
                    path={`${ROUTE.RESET_PASSWORD}/:token`}
                    element={
                        <ResetPassword buttonTitle="button.changePassword" />
                    }
                />
                <Route path="*" element={<Navigate to={ROUTE.LOGIN} />} />
            </Routes>
        </NoAuthLayout>
    )
}

export default NoAuthRoutes
