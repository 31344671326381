import React, { useContext, useEffect, useRef, useState } from 'react'
import { Outlet } from 'react-router-dom'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import useFetchDataById from 'hooks/useFetchDataById'

import { LARGE_SCREEN_WIDTH, LAYOUTS } from 'constants/enums'
import ENTITY from 'constants/entities'

import Footer from './Footer'
import Header from './Header'
import Sidebar from 'components/Sidebar'

const AuthLayout = ({ children }) => {
    const { isSuperAdmin, companyId } = useContext(CurrentUserContext)

    const hocRef = useRef(null)
    const hamBtnRef = useRef(null)

    const [openSidebar, setOpenSidebar] = useState(false)

    const { data } = useFetchDataById(
        ENTITY.COMPANY,
        companyId,
        {
            include:
                'image,country,footerImage,termsAndConditions,privacyPolicy',
        },
        false,
        !isSuperAdmin
    )

    const handleClickOutside = (event) => {
        if (
            document.body.clientWidth <= LARGE_SCREEN_WIDTH &&
            hocRef &&
            hocRef.current &&
            !hocRef.current.contains(event.target) &&
            !hamBtnRef.current.contains(event.target)
        ) {
            setOpenSidebar(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    return (
        <div className="m-auth__wrapper">
            <Sidebar
                open={openSidebar}
                setOpen={setOpenSidebar}
                hocRef={hocRef}
                layout={LAYOUTS.AUTH}
            />
            <div
                className={`m-main -auth ${
                    openSidebar ? '-sidebarIsOpen' : ''
                }`}
            >
                <Header
                    setOpenSidebar={setOpenSidebar}
                    hamBtnRef={hamBtnRef}
                    openSidebar={openSidebar}
                    company={data}
                />
                <main>{children ? children : <Outlet />}</main>
                <Footer company={data} />
            </div>
        </div>
    )
}

export default AuthLayout
