import React, { Fragment, useContext } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import ROUTE from 'constants/routes'

import AuthLayout from '../AuthLayout'
import Header from './Header'

const NoAuthLayout = ({ children }) => {
    const { currentUser } = useContext(CurrentUserContext)
    const { pathname } = useLocation()

    if (pathname === ROUTE.HOME) return <AuthLayout>{children}</AuthLayout>

    return (
        <Fragment>
            <div className="_wr-f">
                <div className="_w">
                    <div className="m-card -form -bg -p _12 _l6">
                        <Header company={currentUser?.company} />
                        {children ? children : <Outlet />}
                    </div>
                    <div className="m-card -noAuth -dark -bg hidden _12 _l6">
                        <div className={'m-card__img'}></div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default NoAuthLayout
