import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import ROUTE from 'constants/routes'

import Icon from 'components/Icon'
import LanguagePicker from 'components/LanguagePicker'

const Header = () => {
    const navigate = useNavigate()

    const { pathname } = useLocation()

    const isRegisterApplicationForm = pathname.includes(
        ROUTE.REGISTER_APPLICATION
    )

    return (
        <header className="m-nav -noAuth">
            <div className="m-nav__logo">
                {isRegisterApplicationForm && (
                    <span className="m-nav__back" onClick={() => navigate(-1)}>
                        <Icon name="back" />
                    </span>
                )}
            </div>

            {/* <LanguagePicker /> */}
        </header>
    )
}

export default Header
