import { useContext } from 'react'
import PropTypes from 'prop-types'

import ROUTES from 'constants/routes'
import { ALL_ROLES, LAYOUTS, ROLES } from 'constants/enums'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

const { ROLE_SUPER_ADMIN, ROLE_COMPANY_ADMIN } = ROLES

const MENU_ITEMS_ADMIN = [
    {
        key: 'campaign-management',
        label: 'menuItems.campaignManagement',
        to: ROUTES.CAMPAIGN_MANAGEMENT,
        roles: ALL_ROLES,
    },
    {
        key: 'user-management',
        label: 'menuItems.userManagement',
        to: ROUTES.USER_MANAGEMENT,
        roles: [ROLE_SUPER_ADMIN, ROLE_COMPANY_ADMIN],
    },
    {
        key: 'category-management',
        label: 'menuItems.categoryManagement',
        to: ROUTES.CATEGORY_MANAGEMENT,
        roles: [ROLE_SUPER_ADMIN, ROLE_COMPANY_ADMIN],
    },
    {
        key: 'company-management',
        label: 'menuItems.companyManagement',
        to: ROUTES.COMPANY_MANAGEMENT,
        roles: [ROLE_SUPER_ADMIN, ROLE_COMPANY_ADMIN],
    },
]

const MENU_ITEMS_AUTH = [
    {
        key: 'user-profile',
        label: 'menuItems.userProfile',
        to: ROUTES.USER_PROFILE,
        roles: ALL_ROLES,
    },
    {
        key: 'admin-panel',
        label: 'menuItems.adminPanel',
        to: ROUTES.CAMPAIGN_MANAGEMENT,
        roles: [ROLE_SUPER_ADMIN, ROLE_COMPANY_ADMIN],
    },
    {
        key: 'my-ideas',
        label: 'menuItems.myIdeas',
        to: ROUTES.MY_APPLICATIONS,
        roles: ALL_ROLES,
    },
    {
        key: 'my-votes',
        label: 'menuItems.myVotes',
        to: ROUTES.MY_VOTES,
        roles: ALL_ROLES,
    },
    {
        key: 'logout',
        label: 'menuItems.logout',
        to: '#',
        roles: ALL_ROLES,
    },
]

const useMenuItems = (layout) => {
    const { currentUser, isCompanyAdmin } = useContext(CurrentUserContext)

    const ADMIN_LAYOUT = layout === LAYOUTS.ADMIN
    const MENU_ITEMS = ADMIN_LAYOUT ? MENU_ITEMS_ADMIN : MENU_ITEMS_AUTH

    const filteredMenuItems = MENU_ITEMS.filter((item) =>
        item.roles.some((role) =>
            currentUser?.userRoles.some(
                (userRole) => userRole.name === role.name
            )
        )
    )

    const companyManagementItem = filteredMenuItems.find(
        (item) => item.key === 'company-management'
    )

    if (companyManagementItem && ADMIN_LAYOUT) {
        companyManagementItem.label = isCompanyAdmin
            ? 'menuItems.companySettings'
            : 'menuItems.companyManagement'
    }

    return filteredMenuItems
}

useMenuItems.propTypes = {
    layout: PropTypes.oneOf([LAYOUTS.ADMIN, LAYOUTS.AUTH]),
}

export default useMenuItems
