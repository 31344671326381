/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import { clearStorage } from 'services/localStorage.service'

import { ROLES } from 'constants/enums'
import ROUTE from 'constants/routes'

import clickOutsideHOC from 'components/wrappers/clickOutsideHOC'
import Avatar from 'components/Avatar'

const AvatarDropdown = ({ open }) => {
    const t = useTranslate()

    const { currentUser, setCurrentUser, isSuperAdmin, isCompanyAdmin } =
        useContext(CurrentUserContext)

    const handleLogout = () => {
        setCurrentUser(null)
        clearStorage()
    }

    if (!currentUser) return null

    const { firstName, lastName, userRoles, avatar } = currentUser

    const roleName = userRoles
        .map((role) => t(ROLES[role.name].label))
        .join(', ')

    return (
        <div className="m-avatar">
            <Avatar
                firstName={firstName}
                lastName={lastName}
                role={roleName ? roleName : ''}
                avatar={avatar?.path}
            />
            <i className={`a-chevron ${open ? '-up' : '-down'}`} />
            <div className={`m-avatar__popup ${open ? '-open' : ''}`}>
                <Link className="m-avatar__item -link" to={ROUTE.USER_PROFILE}>
                    {t('button.userProfile')}
                </Link>
                {(isSuperAdmin || isCompanyAdmin) && (
                    <Link
                        className="m-avatar__item -link"
                        to={ROUTE.CAMPAIGN_MANAGEMENT}
                    >
                        {t('links.header.adminPanel')}
                    </Link>
                )}
                <Link
                    className="m-avatar__item -link"
                    to={ROUTE.MY_APPLICATIONS}
                >
                    {t('button.myIdeas')}
                </Link>

                <Link className="m-avatar__item -link" to={ROUTE.MY_VOTES}>
                    {t('button.myVotes')}
                </Link>

                <Link
                    className="m-avatar__item -link"
                    to="#"
                    onClick={handleLogout}
                >
                    {t('button.logout')}
                </Link>
            </div>
        </div>
    )
}

AvatarDropdown.propTypes = {
    open: PropTypes.bool.isRequired,
}

export default clickOutsideHOC(AvatarDropdown)
