const ENTITY = {
    USER: 'users',
    LANGUAGE: 'languages',
    PROJECT: 'projects',
    ROLE: 'roles',
    COUNTRY: 'countries',
    COMPANY: 'companies',
    APPLICATION: 'applications',
    VOTERS: 'voters',
    APPLICATION_STATUS: 'application_statuses',
    PROJECT_APPLICATION: 'project_applications',
    PROJECT_STATISTIC: 'project_statistics',
    PROJECT_LISTS: 'project_lists',
    VOTER_LISTS: 'voter_lists',
    PROJECT_VOTERS: 'project_voters',
    VOTES: 'votes',
    CATEGORY: 'project_categories',
}

export default ENTITY
