const ROUTE = {
    HOME: '/',
    LOGIN: 'login',
    REGISTER_APPLICATION: 'register-idea',
    FORGOT_PASSWORD: 'forgot-password',
    RESET_PASSWORD: 'reset-password',
    SET_PASSWORD: 'set-password',
    CREATE: 'create',
    EDIT: 'edit',
    CAMPAIGN_MANAGEMENT: 'campaign-management',
    SINGLE_CAMPAIGN: 'single-campaign',
    GENERAL_SETTINGS: 'general-settings',
    VOTERS_LIST: 'voters-list',
    SUBMITTED_APPLICATIONS: 'submitted-ideas',
    USER_MANAGEMENT: 'user-management',
    COMPANY_MANAGEMENT: 'company-management',
    COMPANY_ADMIN_MANAGEMENT: 'company-admin-management',
    VOTER_REGISTER: 'voter-register',
    USER_PROFILE: 'user-profile',
    APPROVED_APPLICATIONS: 'approved-ideas',
    MY_VOTES: 'my-votes',
    MY_APPLICATIONS: 'my-ideas',
    PREVIEW: 'preview',
    CAMPAIGNS: 'campaigns',
    PREVIEW: 'preview',
    CATEGORY_MANAGEMENT: 'category-management',
    NOT_FOUND_PAGE: 'not-found',
}

export default ROUTE
