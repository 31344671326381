import React, { Fragment, useContext, useEffect } from 'react'

import { AlertContext } from 'contexts/AlertContext'
import { useNavigate } from 'react-router-dom'

import ROUTE from 'constants/routes'

const Alert = () => {
    const navigate = useNavigate()

    const {
        alert: { message, type, position },
        setAlert,
    } = useContext(AlertContext)

    const notFoundMessage = message === 'Not Found'

    useEffect(() => {
        notFoundMessage && navigate(ROUTE.NOT_FOUND_PAGE)
    }, [message])

    if (!message) return null

    return (
        <Fragment>
            {!notFoundMessage && (
                <span
                    className={`a-alert -${type} -${position}`}
                    onClick={() => setAlert('')}
                >
                    {message}
                </span>
            )}
        </Fragment>
    )
}

export default Alert
