import React, { Fragment, useContext } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import loadable from 'utils/loadable'

import ROUTES from 'constants/routes'
import { ROLES } from 'constants/enums'

import AuthLayout from '../components/layouts/AuthLayout'
import AdminLayout from 'components/layouts/AdminLayout'

const CampaignManagement = loadable(() =>
    import('screens/admin/CampaignManagement')
)
const SingleCampaign = loadable(() =>
    import('screens/admin/CampaignManagement/SingleCampaign')
)
const GeneralSettings = loadable(() =>
    import('screens/admin/CampaignManagement/SingleCampaign/GeneralSettings')
)
const VotersList = loadable(() =>
    import('screens/admin/CampaignManagement/SingleCampaign/VotersList')
)
const SubmittedApplications = loadable(() =>
    import(
        'screens/admin/CampaignManagement/SingleCampaign/SubmittedApplications'
    )
)
const SingleApplication = loadable(() =>
    import(
        'screens/admin/CampaignManagement/SingleCampaign/SubmittedApplications/SingleApplication'
    )
)
const CompanyManagement = loadable(() =>
    import('screens/admin/CompanyManagement')
)
const CompanyAdminManagement = loadable(() =>
    import('screens/admin/CompanyAdminManagement')
)
const CategoryManagement = loadable(() =>
    import('screens/admin/CategoryManagement')
)
const UserManagement = loadable(() => import('screens/admin/UserManagement'))
const UserProfile = loadable(() => import('screens/admin/UserProfile'))

const AboutCampaign = loadable(() => import('screens/user/AboutCampaign'))
const ApplicationPage = loadable(() => import('screens/user/ApplicationPage'))
const ApprovedApplications = loadable(() =>
    import('screens/user/ApprovedApplications')
)
const RegisterApplication = loadable(() =>
    import('screens/user/RegisterApplication')
)
const EditApplication = loadable(() =>
    import('screens/user/RegisterApplication/EditApplication')
)
const MyVotes = loadable(() => import('screens/user/MyVotes'))
const MyApplications = loadable(() => import('screens/user/MyApplications'))
const CampaignList = loadable(() => import('screens/user/CampaignList'))
const NotFoundPage = loadable(() => import('screens/common/NotFoundPage'))

const { ROLE_SUPER_ADMIN, ROLE_COMPANY_ADMIN, ROLE_VOTER, ROLE_APPLICANT } =
    ROLES

const AuthRoutes = () => {
    const { currentUser } = useContext(CurrentUserContext)

    if (!currentUser) return null

    const { userRoles } = currentUser

    const renderAllowedRoutes = () => {
        switch (true) {
            case userRoles.some((role) => role.name === ROLE_SUPER_ADMIN.name):
                return (
                    <Routes>
                        <Route element={<AdminLayout />}>
                            <Route
                                path={ROUTES.COMPANY_MANAGEMENT}
                                element={<CompanyManagement />}
                            />
                            {renderCommonAdminRoutes()}
                        </Route>
                        {renderCommonRoutes()}
                        <Route
                            path="*"
                            element={
                                <Navigate to={ROUTES.CAMPAIGN_MANAGEMENT} />
                            }
                        />
                    </Routes>
                )

            case userRoles.some(
                (role) => role.name === ROLE_COMPANY_ADMIN.name
            ):
                return (
                    <Routes>
                        <Route element={<AdminLayout />}>
                            <Route
                                path={ROUTES.COMPANY_MANAGEMENT}
                                element={<CompanyAdminManagement />}
                            />
                            {renderCommonAdminRoutes()}
                        </Route>
                        {renderCommonRoutes()}
                        <Route
                            path="*"
                            element={
                                <Navigate to={ROUTES.CAMPAIGN_MANAGEMENT} />
                            }
                        />
                    </Routes>
                )
            case userRoles.some((role) => role.name === ROLE_VOTER.name):
            case userRoles.some((role) => role.name === ROLE_APPLICANT.name):
                return (
                    <Routes>
                        {renderCommonRoutes()}
                        <Route
                            path="*"
                            element={<Navigate to={ROUTES.CAMPAIGNS} />}
                        />
                    </Routes>
                )
            default:
                return <Routes>{renderCommonRoutes()}</Routes>
        }
    }

    const renderCommonAdminRoutes = () => (
        <Fragment>
            <Route path={ROUTES.USER_MANAGEMENT} element={<UserManagement />} />
            <Route
                path={ROUTES.CAMPAIGN_MANAGEMENT}
                element={<CampaignManagement />}
            />
            <Route
                path={`${ROUTES.CAMPAIGN_MANAGEMENT}/${ROUTES.SINGLE_CAMPAIGN}/:campaignId`}
                element={<SingleCampaign />}
            >
                <Route index element={<GeneralSettings />} />
                <Route
                    path={`${ROUTES.GENERAL_SETTINGS}`}
                    element={<GeneralSettings />}
                />
                <Route
                    path={`${ROUTES.VOTERS_LIST}`}
                    element={<VotersList />}
                />
                <Route
                    path={`${ROUTES.SUBMITTED_APPLICATIONS}`}
                    element={<SubmittedApplications />}
                />
            </Route>
            <Route
                path={`${ROUTES.CAMPAIGN_MANAGEMENT}/${ROUTES.SINGLE_CAMPAIGN}/:campaignId/${ROUTES.SUBMITTED_APPLICATIONS}/:applicationId`}
                element={<SingleApplication />}
            />
            <Route
                path={ROUTES.CATEGORY_MANAGEMENT}
                element={<CategoryManagement />}
            />
        </Fragment>
    )

    const renderCommonRoutes = () => {
        return (
            <Fragment>
                <Route element={<AuthLayout />}>
                    <Route path={ROUTES.CAMPAIGNS} element={<CampaignList />} />
                    <Route
                        path={`${ROUTES.CAMPAIGNS}/:projectId`}
                        element={<AboutCampaign />}
                    />
                    <Route
                        path={`${ROUTES.CAMPAIGNS}/:projectId/${ROUTES.APPROVED_APPLICATIONS}`}
                        element={<ApprovedApplications />}
                    />
                    <Route
                        path={`${ROUTES.CAMPAIGNS}/:projectId/${ROUTES.APPROVED_APPLICATIONS}/:applicationId`}
                        element={<ApplicationPage />}
                    />
                    <Route
                        path={`${ROUTES.CAMPAIGNS}/:campaignId/${ROUTES.REGISTER_APPLICATION}`}
                        element={<RegisterApplication />}
                    />
                    <Route
                        path={`${ROUTES.CAMPAIGNS}/:campaignId/${ROUTES.REGISTER_APPLICATION}/${ROUTES.PREVIEW}`}
                        element={<ApplicationPage />}
                    />
                    <Route
                        path={`${ROUTES.CAMPAIGNS}/:campaignId/${ROUTES.REGISTER_APPLICATION}/:applicationId`}
                        element={<EditApplication />}
                    />
                    <Route
                        path={`${ROUTES.CAMPAIGNS}/:campaignId/${ROUTES.REGISTER_APPLICATION}/:applicationId/${ROUTES.PREVIEW}`}
                        element={<ApplicationPage />}
                    />
                    <Route
                        path={ROUTES.MY_APPLICATIONS}
                        element={<MyApplications />}
                    />
                    <Route
                        path={`${ROUTES.MY_APPLICATIONS}/:applicationId`}
                        element={<EditApplication />}
                    />
                    <Route
                        path={`${ROUTES.MY_APPLICATIONS}/:id/${ROUTES.PREVIEW}`}
                        element={<ApplicationPage />}
                    />
                    <Route path={ROUTES.MY_VOTES} element={<MyVotes />} />
                    <Route
                        path={ROUTES.USER_PROFILE}
                        element={<UserProfile />}
                    />
                    <Route
                        path={ROUTES.NOT_FOUND_PAGE}
                        element={<NotFoundPage />}
                    />
                </Route>
            </Fragment>
        )
    }

    return renderAllowedRoutes()
}

export default AuthRoutes
