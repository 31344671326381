import React, { useEffect, useRef, useState } from 'react'
import { Outlet } from 'react-router-dom'

import useWindowDimensions from 'hooks/useWindowDimension'

import { LARGE_SCREEN_WIDTH, LAYOUTS } from 'constants/enums'

import Header from './Header'
import Sidebar from 'components/Sidebar'

const AdminLayout = ({ children }) => {
    const { width } = useWindowDimensions()
    const [openSidebar, setOpenSidebar] = useState(width > LARGE_SCREEN_WIDTH)
    const hocRef = useRef(null)
    const hamBtnRef = useRef(null)

    const handleClickOutside = (event) => {
        if (
            document.body.clientWidth <= LARGE_SCREEN_WIDTH &&
            hocRef &&
            hocRef.current &&
            !hocRef.current.contains(event.target) &&
            !hamBtnRef.current.contains(event.target)
        ) {
            setOpenSidebar(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    useEffect(() => {
        if (width < LARGE_SCREEN_WIDTH) {
            setOpenSidebar(false)
        } else {
            setOpenSidebar(true)
        }
    }, [width])

    return (
        <div>
            <Sidebar
                open={openSidebar}
                setOpen={setOpenSidebar}
                hocRef={hocRef}
                layout={LAYOUTS.ADMIN}
            />
            <div
                className={`m-main ${
                    openSidebar || width > LARGE_SCREEN_WIDTH
                        ? '-sidebarIsOpen'
                        : ''
                }`}
            >
                <Header
                    setOpenSidebar={setOpenSidebar}
                    hamBtnRef={hamBtnRef}
                    openSidebar={openSidebar}
                />
                <div className="m-main__wrapper">
                    <div className="_wr ">
                        <div className="_w">
                            <div className="m-main__content">
                                {children ? children : <Outlet />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminLayout
