import React, { useState } from 'react'

export const DraftContext = React.createContext()

const DraftContextProvider = (props) => {
    const [draft, setDraft] = useState(null)

    return (
        <DraftContext.Provider
            value={{
                draft,
                setDraft,
            }}
        >
            {props.children}
        </DraftContext.Provider>
    )
}

export default DraftContextProvider
